var React = require('react');
require('./styles.less');

function Cargando () {
  return (
    <div id='cargando'>
      Cargando...
    </div>
  )
}

module.exports = Cargando;
