import React from 'react';
import ReactDOM from 'react-dom';
import Programa from 'views/main/programacion/programa';
import './styles.scss';

function ModalPrograma(modalContainer,idPrograma){
    ReactDOM.render(
      <div id='modal-programa' onClick={() => {ReactDOM.render(<div></div>,document.getElementById(modalContainer))}} >
        <div className='modal'>
        </div>
        <div className='programa-container' onClick={() => {ReactDOM.render(<div></div>,document.getElementById(modalContainer))}} >
          <Programa idPrograma={idPrograma} />
        </div>
        <div className='close-trigger' onClick={() => {ReactDOM.render(<div></div>,document.getElementById(modalContainer))}} >
         <i className="far fa-times-circle"></i>
        </div>
      </div>
      ,document.getElementById(modalContainer));
}

export default ModalPrograma;