import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import './styles.scss';
import DatePicker from 'react-date-picker';
import WpSearch from 'wp/search';
import moment from 'moment';

class Filtro extends React.Component {

    constructor(props) {
        super(props);

        var btnSelected = 'hoy';
        if(typeof(props.button)!= 'undefined' && props.button != ''){
            btnSelected = props.button;
        }


        this.state = {
            calOpen: false,
            btnSelected: btnSelected,
            redirect: null
        }
        this.toggleCal = this.toggleCal.bind(this);
    }

    toggleCal() {
        this.setState({
            calOpen: !this.state.calOpen
        })
    }

    toggleTextoInput(){
        this.setState({
            txtOpen: !this.state.txtOpen
        })
    }

    searchByText(){

    }
    
    componentDidUpdate(prevProps){
        //console.log("NEWPROPS BUTTON");
    }

    render() {

        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }

        var barClass = 'normal';
        if(this.props.fixed === true) {
            barClass = 'fixed';
        }

        return (
            <div id='filtro-noticias' className={barClass} >
                <div className='noticias'>NOTICIAS</div>
                <div className='fecha'>{moment(this.props.fecha).format('DD/MM/YYYY')}</div>

                
                { this.props.static ?
                        <ul className='botonera'>
                            <li><Link to={{ pathname: '/', button: 'ayer', filter: moment().subtract(1,'day').format('YYYY-MM-DD[T23:59:00]') }}>AYER</Link></li>
                            <li><Link to={{ pathname: '/', button: 'hoy', filter: moment().format('YYYY-MM-DD[T23:59:00]') }}>HOY</Link></li>
                            <li className='xfecha'><div onClick={() => this.toggleCal()}>FECHA</div>
                                    <DatePicker 
                                        format='dd/MM/yyyy' 
                                        onChange={
                                            (value) => {
                                                this.setState({
                                                    redirect: {
                                                        pathname: '/',
                                                        button: 'fecha',
                                                        filter: moment(value).format('YYYY-MM-DD[T23:59:00]')
                                                    }
                                                })
                                            }
                                        } 
                                        value={new Date()}
                                        isOpen={this.state.calOpen}
                                    />
                                </li>
                                <li className='xtexto'>
                                    <div onClick={() => this.toggleTextoInput()}>BUSCAR</div>
                                    { this.state.txtOpen &&
                                    <div className='texto-input'>
                                    <WpSearch opened={true} />
                                    </div>
                                    }
                                </li>
                               
                        </ul>
                        
                :
                        <ul className='botonera'>
                            { this.state.btnSelected === 'ayer' ? 
                                <li className='selected' onClick={() => this.props.setQuery(moment().subtract(1,'day').format('YYYY-MM-DD[T23:59:00]'))}>AYER</li>
                                :
                                <li onClick={() => {
                                    this.props.setQuery(moment().subtract(1,'day').format('YYYY-MM-DD[T23:59:00]'));
                                    this.setState({
                                        btnSelected: 'ayer'
                                    });
                                }}>AYER</li>
                            }
                            
                            { this.state.btnSelected === 'hoy' ? 
                                <li className='selected' onClick={() => this.props.setQuery(moment().format('YYYY-MM-DD[T23:59:00]'))}>HOY</li>
                                :
                                <li onClick={() => {
                                    this.props.setQuery(moment().format('YYYY-MM-DD[T23:59:00]'));
                                    this.setState({
                                        btnSelected: 'hoy'
                                    });
                                }}>HOY</li>
                            }

                            { this.state.btnSelected === 'fecha' ?
                                <li className='xfecha selected'><div onClick={() => this.toggleCal()}>FECHA</div>
                                    <DatePicker 
                                        format='dd/MM/yyyy' 
                                        onChange={this.props.setQuery} 
                                        value={new Date()}
                                        isOpen={this.state.calOpen}
                                    />
                                </li> 
                            :
                                <li className='xfecha'><div onClick={() => this.toggleCal()}>FECHA</div>
                                    <DatePicker 
                                        format='dd/MM/yyyy' 
                                        onChange={
                                            (value) => {
                                                this.props.setQuery(value);
                                                this.setState({
                                                    btnSelected: 'fecha'
                                                })
                                            }
                                        } 
                                        value={new Date()}
                                        isOpen={this.state.calOpen}
                                    />
                                </li> 
                            }
                            <li className='xtexto'>
                                <div onClick={() => this.toggleTextoInput()}>BUSCAR</div>
                                { this.state.txtOpen &&
                                <div className='texto-input'>
                                    <WpSearch opened={true} />
                                </div>
                                }
                            </li>
                        </ul> 
                        
                }
                
            </div>
        )
    }
};

export default Filtro;