import React from 'react';
import WpMenu from 'wp/menu';
import './styles.scss';

function Social() {
    return (
        <div className='social'>
            <WpMenu location='redes-sociales-menu-location' />
        </div>
    )
};

export default Social;