import React from 'react';
import WpApi from 'wp/api';
import renderHTML from 'react-render-html';
import './styles.scss';

class Programa extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null
    }
  
    this.loadData = this.loadData.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    if(typeof(this.props.idPrograma) !== 'undefined') {
      const options = {
        type: 'programa',
        id: this.props.idPrograma,
        queries: ['_embed']
      }
      WpApi.getItem(options).then(function(item){
        //console.log(item);
        this.setState({
          data: item
        });
      }.bind(this));
    }
  }

  render() {

    var item_image = null;
    var item_image_alt = "";
    if(
      this.state.data !== null &&
      typeof(this.state.data._embedded) !== 'undefined' &&
      typeof(this.state.data._embedded['wp:featuredmedia']) !== 'undefined' &&
      typeof(this.state.data._embedded['wp:featuredmedia'][0].media_details) !== 'undefined' &&
      typeof(this.state.data._embedded['wp:featuredmedia'][0].media_details.sizes) !== 'undefined' &&
      typeof(this.state.data._embedded['wp:featuredmedia'][0].media_details.sizes['thumbnail']) !== 'undefined'       ){
      item_image = this.state.data._embedded['wp:featuredmedia'][0].media_details.sizes['thumbnail'].source_url;
      item_image_alt = this.state.data._embedded['wp:featuredmedia'][0].alt_text;
    }



    return (
      <section id="programa">
      { this.state.data &&
        <div>
          <div className="imagen-programa">
            {item_image ? 
                <img src={item_image} alt={item_image_alt}/>
                :
                <img src='/images/programa.jpg' alt='Programa'/>
            }
          </div>
          <div className="details-programa">
            <div className="descripcion">
              <h4>{this.state.data.title.rendered}</h4>

              { this.state.data.dias_1 !== '' &&
                <div className='grilla'>
                  <span className="dias">{this.state.data.dias_1}</span>
                  <span className="horarios">{this.state.data.horarios_1}</span>
                </div>
              }

              { this.state.data.dias_2 !== '' &&
                <div className='grilla'>
                  <span className="dias">{this.state.data.dias_2}</span>
                  <span className="horarios">{this.state.data.horarios_2}</span>
                </div>
              }

              <div className="staff">

                { this.state.data.conduccion !== '' && 
                  <p><span>Conducen:</span>{this.state.data.conduccion}</p>
                }
                { this.state.data.produccion !== '' &&
                  <p><span>Produce</span>{this.state.data.produccion}</p>
                }
                { this.state.data.operacion !== '' &&
                  <p><span>Opera y Musicaliza</span>{this.state.data.operacion}</p>
                }
                { this.state.data.excerpt.rendered !== '' &&
                  <p className='description'>{renderHTML(this.state.data.excerpt.rendered)}</p>
                }

              </div>
              
            </div>
            <div className="social">
              { this.state.data.redes_facebook !== '' &&
                <div className="social-rounded"><a href={this.state.data.redes_facebook} target='_blank' rel="noopener noreferrer" ><i className="fab fa-facebook-f"></i></a></div>
              }
              { this.state.data.redes_instagram !== '' &&
                <div className="social-rounded"><a href={this.state.data.redes_instagram} target='_blank' rel="noopener noreferrer"><i className="fab fa-instagram"></i></a></div>
              }
              { this.state.data.redes_twitter !== '' &&
                <div className="social-rounded"><a href={this.state.data.redes_twitter} target='_blank' rel="noopener noreferrer"><i className="fab fa-twitter"></i></a></div>
              }
              { this.state.data.redes_youtube !== '' &&
                <div className="social-rounded"><a href={this.state.data.redes_youtube} target='_blank' rel="noopener noreferrer"><i className="fab fa-youtube"></i></a></div>
              } 
              { this.state.data.redes_whatsapp !== '' &&
                <div className="social-rounded"><a href={"https://wa.me/"+this.state.data.redes_whatsapp} target='_blank' rel="noopener noreferrer"><i className="fab fa-whatsapp"></i></a></div>
              }
              { this.state.data.redes_telegram !== '' &&
                <div className="social-rounded"><a href={this.state.data.redes_telegram} target='_blank' rel="noopener noreferrer"><i className="fab fa-telegram-plane"></i></a></div>
              }
              
            </div>
          </div>
        </div>
      }
      </section>
    )
  }
}


export default Programa
