import React from 'react';
import Fondo from './fondo';
import Logo from './logo';
import Weather from './weather';
import Social from './social';
import Menu from './menu';
import Vivo from './vivo';
import WpSearch from 'wp/search';

import './styles.scss';

function Header (props){
    let classHdr = 'home';
    if(props.fixed) {
        classHdr = 'fixed';
    }
    return (
        <section id='header' className={classHdr}>
            <Fondo />
            <Logo />
            <Weather />
            <Social />
            <Menu />
            <Vivo />
            {/*<WpSearch />*/}
        </section>
    )
}

export default Header;