import React from 'react';
import WpMenu from 'wp/menu';
import './styles.scss';

function Publis() {
    return (
        <section id='side-publicidades' >
            <WpMenu location='publis-sidebar-location' />
        </section>
    )
}

export default Publis;