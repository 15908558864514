import React from 'react';
import WpItem from 'wp/item';
import './styles.scss';

const SideQuienesSomos = () => {
    return (
        <section id='side-quienes-somos'>
            <WpItem type='page' slug='quienes-somos-sidebar' />
        </section>
    );
}
export default SideQuienesSomos;