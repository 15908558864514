import React from 'react';
import './styles.scss';

function BarraSuperior () {
    return (
        <div id="programacion-barra-superior">
            <div className='barra'>
                Programación 
            </div>
        </div>  
    )
}

export default BarraSuperior;