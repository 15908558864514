import React from 'react';
import WpMenu from 'wp/menu';
import './styles.scss';

const Footer = (props) => {

    console.log(props);

    let cls = 'hidden';
    if(props.fixed) {
        cls = 'visible';
    }   

    return (
        <section id='footer' className={cls} >
            <WpMenu location='accesos-menu-sidebar-location' />
        </section>
    )

}

export default Footer;