import React from 'react';
import WpList from 'wp/list';
import './styles.scss'

const SideRecientes = () => {
    return (
        <div id="sideRecientes">
        <WpList itemsPerPage={5} />
        </div>
    )
}

export default SideRecientes;