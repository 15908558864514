import React from 'react';
import WpList from 'wp/list';
import WpApi from 'wp/api';
import MenuItem from 'wp/menu-item';
import Filtro from './filtro';
import './styles.scss';
import moment from 'moment';

function Publi(props) {
    return (<div>{props.title}</div>)
}

class MainNoticias extends React.Component {

    constructor(props) {
        super(props);

        var fechaFiltro = moment().format('YYYY-MM-DD[T23:59:00]');
        if(typeof(props.filter) != 'undefined' && props.filter != '') {
            fechaFiltro = props.filter
        }

        this.state = {
            fechaFiltro: fechaFiltro,
            publis: []
        }
        this.setQuery = this.setQuery.bind(this);
    }
    
    setQuery(filtro) {
        //console.log('Se ejecuta filtro: ', filtro);
        if(filtro != null) {
            this.setState({
                fechaFiltro: moment(filtro).format('YYYY-MM-DD[T23:59:00]')
            })
        }
    }

    componentDidMount(){
        this.updatePublis();
    }
  
    updatePublis(){
      this.setState(function () {
        return {
          publis: []
        }
      });
      var opts = {
        location: 'publis-sidebar-location',
      }
  
      WpApi.getMenuIdByLocation(opts)
        .then(function(menu) {
          if(menu && menu.items.length > 0 ) {
            this.setState(function () {
                return {
                    publis: menu.items
                }
            });
          }
        }.bind(this));
    }

    render() {
        const queries = [
            '_embed',
            'orderby=date',
            'order=desc',
            'before='+ this.state.fechaFiltro
        ]

        var clsLoc = 'no-fixed';
        if(this.props.fixed) {
            clsLoc = 'fixed';
        }

        var button = '';
        if(typeof(this.props.button != 'undefined')) {
            button = this.props.button;
        }

        //console.log("PREBUTTON: ",button);

        var filter = '';
        if(typeof(this.props.filter != 'undefined')) {
            filter = this.props.filter;
        }

        let intercalados = [];
        if(this.state.publis){
            intercalados = this.state.publis.map(function (menuItem, index) {
                return (
                    <ul className='publi'><MenuItem key={index} item={menuItem} nivel={1} /></ul>
                )
            })
        }

        console.log("publis:", this.state.publis);
        console.log("intercaladisimos:", intercalados);

        return (
            <section id='main-noticias' className={clsLoc}>
                <Filtro fixed={this.props.fixed} fecha={this.state.fechaFiltro} setQuery={this.setQuery} static={false} button={button} />
                <WpList template={4} queries={queries} debug={false} videoAsFeaturedImage={true} showFeaturedAudio={true} infiniteScroll={true} intercalados={intercalados} frecuenciaIntercalados={2} />
            </section>
        )
    }
};

export default MainNoticias;