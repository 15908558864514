import React from 'react';
import Publis from './publis';
import './styles.scss';

function SideNoticias(props) {

    var cls = 'rayita-no-fixed';
    if(props.fixed) {
        cls = 'rayita-fixed';
    }

    return (
        <section id='side-noticias'>
            <div className={cls}></div>
            <Publis />
        </section>
    )
}

export default SideNoticias;