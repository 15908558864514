import React from 'react';
import SidePrograma from './side-programa';
import BarraSuperior from './barra-superior';
import WpApi from 'wp/api';
import './styles.scss';

class SideProgramacion extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      items: null
    }

    this.loadProgramas = this.loadProgramas.bind(this);
  }

  componentDidMount() {
    this.loadProgramas();
  }

  loadProgramas() {
    this.setState({
      items: null
    })

    const opts = {
      queries: ['orderby=title','order=asc','per_page=100'],
      type: 'programa'
    }

    WpApi.getList(opts).then(function(response){
      this.setState({
        items: response.data
      })
    }.bind(this))
  }

  render() {
    //console.log(this.state.items);
    return (
      <div id="side-programacion">
        <BarraSuperior />
        <div className='lista-programas'>
        { this.state.items != null &&
          this.state.items.map(function(item,index){
            return (
              <SidePrograma key={index} item={item} />
            )
          })
        }
        </div>
      </div>
    )
  }
}

export default SideProgramacion
