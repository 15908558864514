import React from 'react';
import axios from 'axios';
import './styles.scss';

class Weather extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            clima: null
        }
        this.checkClima = this.checkClima.bind(this);
    }

    componentDidMount() {
        this.checkClima();
    }

    checkClima() {
        var api_key = '63decdeeddbd8cea01db7750fcb6722c';
        var url = 'https://api.openweathermap.org/data/2.5/weather?q=Viedma&appid=' + api_key + '&units=metric';
        axios.get(url)
            .then(function(response) {
            
                this.setState({ clima: {
                    temp: Math.floor(response.data.main.temp),
                    hum: response.data.main.humidity,
                    icon: 'https://openweathermap.org/img/w/' + response.data.weather[0].icon + '.png' 
                }})
                //console.log("CLIMAAAA: ",response);
            
            }.bind(this));
    }

    render() {

        var meses = new Array ("Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre");
        var diasSemana = new Array("Domingo","Lunes","Martes","Miércoles","Jueves","Viernes","Sábado");
        var f= new Date();
        var fecha = f.getDate() + " de " + meses[f.getMonth()] + " de " + f.getFullYear();

        return (
        <div className='weather'>
            <div className='fecha'><strong>Viedma</strong> {fecha}</div>
            { this.state.clima &&
            <div className='clima'>
                <img src={this.state.clima.icon} />
                <div className='grados'>{this.state.clima.temp}º - Hum {this.state.clima.hum}%</div>
            </div>
            }
            
        </div>
        )
    };
}

export default Weather;