import React from 'react'
import axios from 'axios';
import moment from 'moment';
import Programa from './programa';
import DialBar from './barra-dial';

import './styles.scss';

moment.locale('es');

class MainProgramacion extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      items: [],
      week: [],
      selectedDay: null,
      modalOpen: false,
      modalItem: null
    }

    this.buildWeek = this.buildWeek.bind(this);
    this.loadDay = this.loadDay.bind(this);
    this.selectDay = this.selectDay.bind(this);

    this.getEvents = this.getEvents.bind(this);
    this.addEvents = this.addEvents.bind(this);
    this.onSelectEvent = this.onSelectEvent.bind(this);
  }

  componentDidMount(){
    this.buildWeek();
    this.loadDay(new Date()); //today
  }

  selectDay(index) {
    this.loadDay(this.state.week[index].date.toDate());
    this.setState({
      selectedDay: index
    })
  }

  buildWeek() {
    const dayNames = [
      {
        id: 0,
        name: 'Domingo',
        abr: 'Dom',
      },
      {
        id: 1,
        name: 'Lunes',
        abr: 'Lun',
      },
      {
        id: 2,
        name: 'Martes',
        abr: 'Mar',
      },
      {
        id: 3,
        name: 'Miércoles',
        abr: 'Mie',
      },
      {
        id: 4,
        name: 'Jueves',
        abr: 'Jue',
      },
      {
        id: 5,
        name: 'Viernes',
        abr: 'Vie',
      },
      {
        id: 6,
        name: 'Sábado',
        abr: 'Sab',
      },
    ]

    const today = moment()
    //console.log('today: ', today );
    const week = []
    for (var i = 0; i < 7; i++) {
      let day = moment(today);
      day.add(i, 'days');
      const dayNumber = parseInt(day.format('e'));
      //console.log('day: ', day, ' plus: ', i,  'dayNumber: ', parseInt(day.format('e')));
      const weekday = {
        number: dayNumber,
        name: dayNames.find(element => element.id === dayNumber),
        date: day
      }
      week.push(weekday);
    }
    //console.log('Week: ', week);
    this.setState({
      week: week,
      selectedDay: 0
    })

  }

  loadDay(d) {

    const calendarios = [
      {
          nombre: "Programación",
          apiKey: "AIzaSyBjLo6XrR6GXPAlkzdATLN0QozxKSpfkAA",
          calId: "c_tog0p8q097hridabu1rj5umr3o@group.calendar.google.com",
          clase: "calradio"
      },
    ];

    var minDate = new Date(d.getTime() - 180*60*1000);
    var maxDate = new Date(d.getTime() - 180*60*1000);

    maxDate.setDate(maxDate.getDate() + 1);
    if(this.props.debug){
      console.log("max",maxDate)
    }
    this.getEvents(calendarios,moment(minDate).format('YYYY-MM-DD[T03:00:00Z]'),moment(maxDate).format('YYYY-MM-DD[T03:00:00Z]'));
  }

  getEvents(sources,min,max){
    if(this.props.debug){
      console.log("trae eventos de: "+min+" a "+max);
    }
    const baseUrl = "https://www.googleapis.com/calendar/v3/calendars/";
    this.setState({
        items: [],
    })
    sources.map(function(source){
        this.addEvents(baseUrl+source.calId+'/events?key='+source.apiKey+'&timeMin='+min+'&timeMax='+max+'&showDeleted=false&singleEvents=true&orderBy=startTime',min,max,source.clase);
    }.bind(this));
  }

  addEvents(url,min,max,clase){
      if(this.props.debug){
        console.log("resultado: ",url,min,max,clase);
      }
      axios.get(url)
      .then(function(response) {
          this.setState(function(){
              var events = [];
              if(response.data.items){
                  events = response.data.items.map(function(event){

                      var start = event.start.date;
                      var end = event.end.date;
                      var allDay = false;

                      /* ARREGLO ALL DAY EVENTS */
                      if(!event.start.dateTime) {
                          var endObj = new Date(event.start.date);
                          endObj.setDate(endObj.getDate() + 1);
                          start =  endObj.toISOString().substring(0, 10);
                          allDay = true;
                      } else {
                          start = event.start.dateTime;
                          end = event.end.dateTime;
                      }


                      return {
                          start: new Date(start),
                          end: new Date(end),
                          allDay: allDay,
                          title: event.summary,
                          desc: event.description,
                          clase: clase,
                      }
                  });
                  if(this.props.debug){
                      console.log(events);
                  }
              }
              return {
                  mes: min,
                  items: this.state.items.concat(events),
              }
          }.bind(this));
      }.bind(this));
  }

  onSelectEvent(item){
    if(this.props.debug){
      console.log(item);
    }
  }

  render() {

    return (
      <section id='main-programacion'>
        <DialBar week={this.state.week} selectedDay={this.state.selectedDay} selectDay={this.selectDay} />         
        <ul className='programas-list'>
        {this.state.items
        ?
          this.state.items.map(function(item,index){
                //console.log("Evento Calendar",item);
                return (
                    <li key={index} onClick={function(){this.onSelectEvent(item)}.bind(this)} >
                        <div className="espacio">
                            <Programa idPrograma={item.desc} />
                        </div>
                    </li>
                )}.bind(this))
        :
        <li>No hay programas para hoy</li>
        }
        </ul>
      </section>
    )
  }
}

export default MainProgramacion;
