import React from 'react';
import Header from 'views/header';
import Footer from 'views/footer';
import Main from 'views/main';
import Sidebar from 'views/sidebar';
import DialBar from './views/main/programacion/barra-dial';
import 'styles/main.scss';
import 'styles/layout.scss';

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        fixed: props.location.pathname !== '/',
    }
  }

  checkScroll = () => {
    let fixedHeader = true;
    if(this.props.location.pathname === '/' && window.scrollY < 222) {
        fixedHeader = false;
    }
    if(fixedHeader != this.state.fixed) {
      this.setState({
          fixed: fixedHeader
      })
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.checkScroll);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.checkScroll();
    }
  }

  render() {

    let fixedCls = 'no-fixed';
    if(this.state.fixed && this.props.location.pathname !== '/') {
      fixedCls = 'fixed';
    }

    return (
      <div className="app">
        <Header fixed={this.state.fixed} />
        <section id='content' className={fixedCls}>
          <Main 
            fixed={this.state.fixed} 
            location = {this.props.location.pathname}
          />
          <Sidebar 
            fixed={this.state.fixed}
            location = {this.props.location.pathname}
          /> 
        </section>
        <Footer fixed={this.state.fixed} /> 
      </div>
    );
  }
}

export default App;
