import React from "react";
import WpMenu from 'wp/menu';
import WpItem from 'wp/item';
import './styles.scss';

class SideSuscripcion extends React.Component {

    render() {
        return (
            <section id='side-suscripcion'>
                <WpMenu location='aportes-suscripcion-location' />
                <WpItem type='page' slug='suscripcion-sidebar' />
            </section>
        )
    }

}

export default SideSuscripcion;