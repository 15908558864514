import React, {useState} from 'react';
import { Route, Switch } from 'react-router-dom';

import SideNoticias from './noticias';
import SideRecientes from './recientes';
import SideMenu from './menu';
import SideProgramacion from './programacion';
import SideQuienesSomos from './quienes-somos';
import SideSuscripcion from './suscripcion';

import './styles.scss';

function Sidebar(props) { 

    let cls = 'visible';
    if(props.location === '/menu') {
        cls = 'hidden';
    }

    return (
        <section id='sidebar' className={cls}>
            <Switch>
                <Route exact path='/'><SideNoticias fixed={props.fixed} /></Route>
                <Route exact path='/programacion'><SideProgramacion /></Route>
                <Route exact path='/quienes-somos'><SideQuienesSomos /></Route>
                <Route exact path='/suscripcion'><SideSuscripcion /></Route>
                <Route exact path='/formulario-de-suscripcion'><SideSuscripcion /></Route>
                <Route path='/:slug1/:slug2?/:slug3?' render={ function(props) { return ( <SideRecientes /> ) } } />
            </Switch>
        </section>
    )

};

export default Sidebar;