import React from 'react'
import ModalPrograma from '../modal-programa'
import './styles.scss'

// import PropTypes from 'prop-types'

const SidePrograma = (props) => {
  return (
    <div id="side-programa">
      <div className="icon">
      <i className="fas fa-microphone"></i>
      </div>
      <div className="titulo" onClick={() => ModalPrograma('full-modal',props.item.id) }>{props.item.title.rendered}</div> 
    </div>
  )
}

export default SidePrograma
