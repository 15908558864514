import React from 'react';
import { Link } from 'react-router-dom';
import WpMenu from 'wp/menu';
import './styles.scss';

function Menu() {
    return (
    <section id='menu'>
        <WpMenu location='site-menu-location' />
        <div className='menu-trigger'><Link to='/menu'><i className="fas fa-bars"></i></Link></div>
    </section>
    )
};

export default Menu;