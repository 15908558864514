import React from 'react';
import './styles.scss';

function HeaderFondo() {
    return(
        <div className='fondo' >
            <video autoPlay={true} loop={true}>
                <source src="/video-header.mp4" type="video/mp4" />
            </video>
        </div>
    )
}

export default HeaderFondo;