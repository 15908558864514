import React from 'react'
import './styles.scss'

const DialBarr = (props) => {
  return (
    <section id="dial">
        <div className="main-dial">
          <div className="tuner"><span className="arrow"></span></div>
          <div className="progra-days">
            { props.week !== null && props.week.map(function(item,index){
              return (              

                <div className="day" key={index}>
                  { props.selectedDay !== index ?
                    <a onClick={() => props.selectDay(index)}>
                      <span className="inner"></span>
                      <span className="inner"></span>
                        {item.name.abr}
                      <span className="inner"></span>
                      <span className="inner"></span>
                    </a>
                  :
                    <div>
                      <span className="inner"></span>
                      <span className="inner"></span>
                        {item.name.name}
                      <span className="inner"></span>
                      <span className="inner"></span>
                    </div>
                  }
              </div> 
              )})
            }
          </div>
        </div> 
    </section>
  )
}

export default DialBarr
