import React from 'react';
import axios from 'axios';
import moment from 'moment';
import './styles.scss';

moment.locale('es');

class Vivo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            player: null,
            playing: false,
            volume: 0,
            programas: [],
            currentProgram: 'En vivo...'
        }
    
        this.changePlayState = this.changePlayState.bind(this);
        this.fetchDayProgram = this.fetchDayPrograms.bind(this);
        this.checkCurrentProgram = this.checkCurrentProgram.bind(this);
    }

    componentDidMount() {
        this.setState({
            player: new Audio('https://encuentro.redenfoques.com.ar/encuentro')
        });
        this.fetchDayPrograms();
        this.timer = setInterval(() => this.checkCurrentProgram(), 60000);
    }

    checkCurrentProgram() {
        var d = new Date();
        var current = 'CARGANDO...'
        if(this.state.programas.length > 0) {
            var program = this.state.programas.find(element => {
                //console.log(d, element.start, element.end, element.start > d && element.end < d);
                return element.start < d && element.end > d
            });
            //console.log("SELECCIONADO: ", program);
            if(typeof(program) !== 'undefined') {
               current = program.title;
            }
        }
        this.setState({
            currentProgram: current
        });
    }

    changePlayState() {
        if(this.state.playing === false) {
            this.state.player.play();
            //this.initPlayer();
            this.setState({
                playing: true
            })
        } else {
            this.state.player.pause();
            this.setState({
                playing: false
            })
        }
    }

    fetchDayPrograms() {
        const calendarios = [
            {
                nombre: "Programación",
                apiKey: "AIzaSyBjLo6XrR6GXPAlkzdATLN0QozxKSpfkAA",
                calId: "c_tog0p8q097hridabu1rj5umr3o@group.calendar.google.com",
                clase: "calradio"
            },
          ];
        
          var d = new Date();

          var minDate = new Date(d.getTime() - 180*60*1000);
          var maxDate = new Date(d.getTime() - 180*60*1000);
      
          maxDate.setDate(maxDate.getDate() + 1);
          //console.log("max",maxDate);
 
          var min = moment(minDate).format('YYYY-MM-DD[T00:00:00Z]');
          var max = moment(maxDate).format('YYYY-MM-DD[T00:00:00Z]');

          //console.log("trae eventos de: "+min+" a "+max);
          const baseUrl = "https://www.googleapis.com/calendar/v3/calendars/";
          this.setState({
              items: [],
          })
          calendarios.map(function(source){

            var url = baseUrl+source.calId+'/events?key='+source.apiKey+'&timeMin='+min+'&timeMax='+max+'&showDeleted=false&singleEvents=true&orderBy=startTime';
            
            //console.log("resultado: ",url,min,max,source.clase);
            axios.get(url)
              .then(function(response) {
                  this.setState(function(){
                      var events = [];
                      if(response.data.items){
                          events = response.data.items.map(function(event){
        
                              var start = event.start.date;
                              var end = event.end.date;
                              var allDay = false;
        
                              /* ARREGLO ALL DAY EVENTS */
                              if(!event.start.dateTime) {
                                  var endObj = new Date(event.start.date);
                                  endObj.setDate(endObj.getDate() + 1);
                                  start =  endObj.toISOString().substring(0, 10);
                                  allDay = true;
                              } else {
                                  start = event.start.dateTime;
                                  end = event.end.dateTime;
                              }
        
        
                              return {
                                  start: new Date(start),
                                  end: new Date(end),
                                  allDay: allDay,
                                  title: event.summary,
                                  desc: event.description,
                                  clase: source.clase,
                              }
                          });
                          if(this.props.debug){
                              console.log(events);
                          }
                          this.setState({
                              programas: events
                          })
                      }
                      return {
                          mes: min,
                          items: this.state.items.concat(events),
                      }
                  }.bind(this));
              }.bind(this));
        
           }.bind(this));
    }

    /*
    initPlayer() {
        var context = new AudioContext(); //AudioContext object instance
        var analyser = context.createAnalyser(); // AnalyserNode method
        
        //creating a HTML5 canvas
        var canvas = document.getElementById('vivo_bars');
        var ctx = canvas.getContext('2d');
        
        // Re-route audio playback into the processing graph of the AudioContext
        var source = context.createMediaElementSource(this.state.player);
        source.connect(analyser);
        analyser.connect(context.destination);
        console.log('AN1: ',analyser);
        this.frameLooper(analyser, ctx, canvas);//calling the frameLooper function        
    }

    frameLooper(analyser, ctx, canvas){
        window.requestAnimationFrame(this.frameLooper);//recommended method instead of setInterval and setTimeout methods,
        var fbc_array = new Uint8Array(analyser.frequencyBinCount);//this data will be stored as an arrray of the representation of the sound frequency data
        console.log('AN2: ',analyser);
        analyser.getByteFrequencyData(fbc_array);
        ctx.clearRect(0, 0, canvas.width, canvas.height); //clear the canvas
        ctx.fillStyle = '#00ccff'; //color of the bars, changeable
        var bars = 100; //number of bars, changeable

        //for loop renders the 100 bars at different heights according the sound frequency many times per second
        var bar_x, bar_width, bar_height;
        for (var i = 0; i < bars; i++) {
            bar_x = i * 3;//set the x position of each bars, changeable
            bar_width = 2;//bar width in pixels, changeable
            bar_height = -(fbc_array[i] / 2); //bar height. this is what makes the bars dance up and down. we take the frequency data for the current index(i) in the fbc_array then divide by 2

            //fillRect (x, y, width, height) //Explanation of the parameters below
            ctx.fillRect(bar_x, canvas.height, bar_width, bar_height);//draws each bar
        }
    };
    */
    
    render(){
        return (
            <section id='vivo'>
                {/*
                <div className="play-pause-bnt">
                    <a href='http://redenfoques.com.ar:8000/encuentro' target='blank'><i className="fas fa-play"></i></a>
                </div>
                */}
                
                <div className="play-pause-bnt" onClick={() => this.changePlayState()}>
                    { this.state.playing ?
                        <i className="fas fa-pause"></i>
                    :
                        <i className="fas fa-play"></i>
                    }
                </div>
                
                <div className="info">
                    { !this.state.playing &&
                        <div className="sintonia">103.9 <span className="mhz">Mhz</span> </div>
                    }
                    
                    {/*<canvas id='vivo_bars'></canvas>*/}

                    { !this.state.playing &&
                    <div className="titulo">
                        <span>en </span>vivo
                    </div>
                    }
                    { !this.state.playing &&
                        <div className='clasico'>
                            <a href='https://radios.redenfoques.com.ar/encuentro' target='blank'>
                                <span>OPCIÓN 2<br/>REPRODUCTOR</span>
                                <i className="fas fa-external-link-alt"></i>
                            </a>
                        </div>
                    }
                    { this.state.playing &&
                        <div className='programa'><div className='texto'>{this.state.currentProgram}</div></div>
                    }
                </div>
            </section>
        )
    }
};

export default Vivo;