import React from 'react';
import WpItem from 'wp/item';
import './styles.scss';

function MainSuscripcion() {
    return (
        <section id='suscripcion'>
            <div className='wrapper-central'>
                <WpItem type='page' slug='suscripcion-main' />
            </div>
        </section>
    )
};

export default MainSuscripcion;