import React from 'react';
import WpMenu from 'wp/menu';

import './styles.scss';

function MainMenu() {
    return (
        <section id='main-menu' className="main-container">
            <WpMenu location='site-menu-location' />
        </section>
    )
}

export default MainMenu;